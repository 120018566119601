import React from 'react'

import Layout from '../components/layout'

const DegreesPage = () => {
  return (
    <Layout>
      <div className='text-3xl my-6 text-nittanyNavy text-center md:my-14 lg:text-6xl'>
        Degrees
      </div>
      <div className='text-primary mx-5 md:mx-auto md:max-w-xl lg:max-w-4xl xl:max-w-5xl'>
        <div className='flex flex-col my-4'>
          <span className='text-nittanyNavy my-1 text-xl md:text-3xl'>
            Bachelor of Arts
          </span>
          <span>
            Take a broad range of classes in the School of Music, choosing a
            focus in Music Technology or General Music Studies.
          </span>
          <span>
            <a
              href='https://music.psu.edu/admissions/degrees/bachelor-arts'
              target='_blank'
              rel='noreferrer'
            >
              Learn More
            </a>
          </span>
        </div>

        <div className='flex flex-col my-4'>
          <span className='text-nittanyNavy my-1 text-xl md:text-3xl'>
            Bachelor of Music
          </span>
          <span>
            The Bachelor of Music is your next step to a professional career as
            a performer or composer. Students pursuing this degree select from
            four options: Composition; Keyboard Instruments; Strings, Winds,
            Brass, and Percussion Instruments; or Voice.
          </span>
          <span>
            <a
              href='https://music.psu.edu/admissions/degrees/bachelor-music'
              target='_blank'
              rel='noreferrer'
            >
              Learn More
            </a>
          </span>
        </div>

        <div className='flex flex-col my-4'>
          <span className='text-nittanyNavy my-1 text-xl md:text-3xl'>
            Bachelor of Musical Arts
          </span>
          <span>
            Music is your passion, but it's not your only passion. The Bachelor
            of Musical Arts allows you to pursue your interests in music as well
            as other areas, uniquely qualifying you for a wide variety of
            careers in performance.
          </span>
          <span>
            <a
              href='https://arts.psu.edu/degrees/bachelor-of-musical-arts/'
              target='_blank'
              rel='noreferrer'
            >
              Learn More
            </a>
          </span>
        </div>

        <div className='flex flex-col my-4'>
          <span className='text-nittanyNavy my-1 text-xl md:text-3xl'>
            Bachelor of Music Education
          </span>
          <span>
            Music educators have the unique privilege of sharing both their
            knowledge of music and their passion for it. In the Bachelor of
            Music Education program, you'll develop your teaching skills and
            your own musicianship by working with award-winning faculty whose
            experience spans every type of educational institution
          </span>
          <span>
            <a
              href='https://arts.psu.edu/degrees/bachelor-of-music-education/'
              target='_blank'
              rel='noreferrer'
            >
              Learn More
            </a>
          </span>
        </div>

        <span className='py-4'>
          <a
            href='https://music.psu.edu/admissions'
            target='_blank'
            rel='noreferrer'
          >
            See a more detailed list of Penn State's music degrees here
          </a>
        </span>
      </div>
    </Layout>
  )
}

export default DegreesPage
